import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { SPRING_HOUSE_COORDS, BALLARDS_COORDS, FERRY_COORDS} from '../components/Directions'

const API_KEY = process.env.GATSBY_GOOGLE_API_KEY

export const TravelPageTemplate = ({ title, image, content, contentComponent }) => {
  const PageContent = contentComponent || Content
  const apiKeyParam = API_KEY ? "&key=" + API_KEY : ""
  return (
    <section className="section section--gradient">
      <div
        className="banner-image margin-top-0"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
          maxWidth: '1000px',
          backgroundPosition: `center right`,
        }}
      />
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <div className="content">
                <p>Please book accomodations as soon as possible! Some locations are fully booked six months in advance! See <a href="#lodging">lodging</a> for help with finding sleeping arrangements and feel free to reach out to us.</p>
                <h2>The Quick Version</h2>
                <ol>
                  <li>Find transportation to <strong>Block Island, RI</strong> before <strong>July 30, 2022 at 12:00PM</strong>.</li>
                  <li>Arrive at the <strong>Spring House</strong> on <strong>Block Island, RI</strong> before the <strong>3:00PM</strong> start time of the ceremony.</li>
                  <li>Reception will follow the ceremony until <strong>9:00PM</strong>.</li>
                  <li>Optional bonfire at <strong>Ballard's</strong> following the reception until <strong>midnight</strong>. <Link to='/contact'>Contact us</Link> if you plan on attending the bonfire!</li>
                  <li>Book accommodations on Block Island including the evening of <strong>July 30, 2022</strong>.</li>
                </ol>
                <p>It's a good idea to bring cash with you as some businesses are cash only. ATMs are available on the island as well if you need to use them.</p>
              </div>
              <p className="is-size-5 has-text-centered">
                <a href={`https://www.google.com/maps/dir/?api=1&travelmode=walking&origin=${FERRY_COORDS.lat},${FERRY_COORDS.lng}&destination=${BALLARDS_COORDS.lat},${BALLARDS_COORDS.lng}&waypoints=${SPRING_HOUSE_COORDS.lat},${SPRING_HOUSE_COORDS.lng}`}>Open in Google Maps</a>
              </p>
              <br />
              <PageContent className="content" content={content} />
              <p className="is-size-3 has-text-centered">We look forward to seeing you there!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

TravelPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  image: PropTypes.object,
  contentComponent: PropTypes.func,
}

const TravelPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <TravelPageTemplate
        contentComponent={HTMLContent}
        image={post.frontmatter.image}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

TravelPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TravelPage

export const travelPageQuery = graphql`
  query TravelPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
