import React from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker, DirectionsRenderer } from "react-google-maps"
import { compose, lifecycle } from "recompose"

export const SPRING_HOUSE_COORDS = { lat: 41.1679449, lng: -71.554813 }
export const FERRY_COORDS = { lat: 41.1736512, lng: -71.5566695 }
export const BALLARDS_COORDS = { lat: 41.173094, lng: -71.554597}

const DirectionsMap = compose(withScriptjs, withGoogleMap, lifecycle({
  componentDidMount() {
    const DirectionsService = new google.maps.DirectionsService()

    DirectionsService.route({
      origin: FERRY_COORDS,
      waypoints: [{
        location: SPRING_HOUSE_COORDS,
        stopover: true
      }],
      destination: BALLARDS_COORDS,
      travelMode: 'WALKING',
    }, (result, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        this.setState({
          directions: result,
        })
      } else {
        console.error(`error fetching directions ${result}`)
      }
    })
  }
}))((props) => {
  const ringImage = {
    url: '/img/maps/heart.svg',
    scaledSize: {
      width: 40,
      height: 40
    }
  }
  const bonfireImage = {
    url: '/img/maps/bonfire.png',
    scaledSize: {
      width: 30,
      height: 30
    }
  }
  const FERRY_SIZE = 60
  const ferryImage = {
    url: '/img/maps/boat.svg',
    scaledSize: {
      width: FERRY_SIZE,
      height: FERRY_SIZE
    },
    anchor: {
      x: 1/2 * FERRY_SIZE,
      y: 4/7 * FERRY_SIZE
    }
  }
  return (
    <GoogleMap
      defaultZoom={15.5}
      defaultCenter={{ lat: 41.1709784, lng: -71.5554821 }}
    >
      {props.directions && <DirectionsRenderer options={{preserveViewport: true}} directions={props.directions} />}
      <Marker position={SPRING_HOUSE_COORDS} icon={ringImage} />
      <Marker position={FERRY_COORDS} icon={ferryImage} />
      <Marker position={BALLARDS_COORDS} icon={bonfireImage} />
    </GoogleMap>
  )
})

export default DirectionsMap